<template>
  <div class="wrapper">
    <v-card>
      <v-card-title>
        Templates
        <v-spacer />
        <v-btn x-small @click="resetTemplates" :loading="loadingResetTemplates">
          <v-icon x-small>mdi-sync</v-icon>
          Reset
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-feature-search-outline"
          label="Search"
          single-line
          hide-details
          autofocus
        />
        <v-data-table
          :headers="headers"
          :items="fetchedTemplates"
          :loading="isLoading"
          :footer-props="{ 'items-per-page-options': rowsPerPageItems }"
          :options="options"
          :mobile-breakpoint="null"
          item-key="external_id"
          class="elevation-1"
          :search="search"
          v-model="selectedTemplates"
        >
          <template v-slot:[`item.name`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            {{ item.name }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" @click="openInBannerbear(item, true)">
                  mdi-open-in-new
                </v-icon>
              </template>
              <span style="font-size: 12px">Edit in Bannerbear</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.preview_url`]="{ item }">
            <v-menu open-on-hover close-delay="150" open-delay="150">
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                  <img :src="item.preview_url" height="60" />
                </span>
              </template>
              <img class="pa-2" :src="item.preview_url" height="500" />
            </v-menu>
          </template>
          <template v-slot:[`item.ratio`]="{ item }">
            {{ formatName(item.ratio) }}
          </template>
          <template v-slot:[`item.topic_sub_ids`]="{ item }">
            <span v-if="item.topic_sub_ids && item.topic_sub_ids.length > 0">
              {{ item.topic_sub_ids[0] === "all" ? "All" : "Partial" }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="justify-right">
        <v-spacer></v-spacer>

        <v-btn
          rounded
          :loading="isLoading"
          elevation="2"
          raised
          text
          color="primary"
          dark
          @click="fetchTemplates"
        >
          Refresh
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="editDialog" persistent max-width="1000px">
      <v-card>
        <v-card-title class="text-h5">Edit template</v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-switch
                v-model="editedItem.enabled"
                :label="`Enabled: ${editedItem.enabled.toString()}`"
              />
            </v-row>
            <v-row>
              <!-- <v-col cols="12" sm="6" md="4"> -->
              <span class="form-control__name">Tags</span>
              <v-combobox
                :delimiters="[',', ' ', ';', '#']"
                @change="tagsChanged"
                v-model="editedItem.tags"
                chips
                hide-details
                class="tags_container"
                label="Tags"
                multiple
                solo
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="deleteTagHandler(item)"
                  >
                    <strong>{{ item }}</strong>
                  </v-chip>
                </template>
              </v-combobox>
              <!-- </v-col> -->
              <!-- <v-col cols="12" sm="6" md="4"> -->
            </v-row>
            <v-row>
              <v-select
                :items="optionalBrandStyles"
                multiple
                v-model="editedItem.styles"
                item-text="name"
                item-value="name"
                label="Brand Styles"
              />
            </v-row>
            <v-row>
              <!-- </v-col>
              <v-col cols="12" sm="6" md="4"> -->
              <v-select
                v-model="editedItem.domains"
                :items="['real_estate', 'beauty', 'ecommerce']"
                label="Domains"
                required
                multiple
              ></v-select>
              <v-checkbox
                v-model="editedItem.use_pro_images"
                :label="`Use PR Images: ${editedItem.use_pro_images || false}`"
              ></v-checkbox>
              <!-- </v-col> -->
            </v-row>
            <v-row>
              <!-- </v-col>
              <v-col cols="12" sm="6" md="4"> -->
              <v-select
                v-model="editedItem.template_type"
                :items="currentEditOptionalTemplateTypes"
                label="Types"
                item-text="name"
                item-value="name"
              ></v-select>
              <!-- </v-col> -->
            </v-row>
            <TopicsSelection
              :forceAllTopics="forceAllTopics"
              :optionalTopics="currentEditOptionalTopics"
              :previousSelectedTopicSubIds="editedItem.topic_sub_ids"
              :editDialog="editDialog"
              @select="topicsSelectionResults($event)"
            />
            <CustomerSelection
              :forceAllCustomers="forceAllCustomers"
              :optionalCustomers="currentEditOptionalCustomers"
              :previousSelectedCustomerSubIds="editedItem.customer_ids"
              :editDialog="editDialog"
              @select="customerSelectionResults($event)"
            />
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="editDialog = false"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>

          <v-btn color="blue darken-1" text @click="saveItemConfirm"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  SUPPORTED_BRAND_STYLES,
  SUPPORTED_TEMPLATE_TYPES,
} from "@/constants/globals";
import TopicsSelection from "@/components/calendar/TopicsSelection";
import CustomerSelection from "@/components/customer/CustomerSelection";

import H from "@/utils/helper";

export default {
  name: "TemplatesList",

  components: {
    TopicsSelection,
    CustomerSelection,
  },
  data: () => ({
    editedIndex: null,
    editedItem: {
      use_pro_images: false,
      tags: [],
      styles: [],
      domains: [],
      enabled: true,
      topic_sub_ids: [],
      template_type: null,
      customer_ids: [],
    },
    currentEditOptionalTopics: [],
    currentEditOptionalCustomers: [],
    currentEditOptionalTemplateTypes: [],
    topicsBeauty: [],
    topicsRealestate: [],
    editDialog: false,
    forceAllTopics: false,
    forceAllCustomers: true,
    isLoading: true,
    isError: false,
    search: "",
    fetchedTemplates: [],
    selectedTemplates: [],
    optionalBrandStyles: SUPPORTED_BRAND_STYLES,
    optionalTemplateTypes: SUPPORTED_TEMPLATE_TYPES,
    loadingResetTemplates: false,
    headers: [
      {
        text: "name",
        value: "name",
      },
      {
        text: "external_id",
        value: "external_id",
        align: " d-none",
      },
      {
        text: "preview",
        value: "preview_url",
      },
      {
        text: "enabled",
        value: "enabled",
      },
      {
        text: "domains",
        value: "domains",
      },
      {
        text: "styles",
        value: "styles",
      },
      {
        text: "topics",
        value: "topic_sub_ids",
      },
      {
        text: "tags",
        value: "tags",
      },
      {
        text: "type",
        value: "template_type",
      },
      {
        text: "customers",
        value: "customer_ids",
      },
      {
        text: "images",
        value: "images_count",
      },
      // {
      //   text: "metadata",
      //   value: "metadata",
      // },
      // {
      //   text: "created_at",
      //   value: "created_at",
      // },
      // {
      //   text: "permalink",
      //   value: "permalink",
      // },
      {
        text: "orientation",
        value: "ratio",
      },
      // {
      //   text: "updated_at",
      //   value: "updated_at",
      // },
      // {
      //   text: "width",
      //   value: "width",
      // },
      // {
      //   text: "height",
      //   value: "height",
      // },
    ],
    rowsPerPageItems: [100, 200],
    options: {
      sortBy: ["name"],
      // sortDesc: false,
      // multiSort: false,
      // itemsPerPage: 100,
    },
  }),

  async created() {
    await this.fetchTemplates();
    await this.fetchTopics();
  },
  methods: {
    ...mapActions("templates", [
      "listTemplates",
      "modifyTemplate",
      "refreshAllTemplates",
    ]),
    ...mapActions("calendar", ["getTopicList"]),

    formatName(ratio) {
      const ratioNames = {
        square: "Square (Post)",
        vertical: "Portrait (Story)",
        horizontal: "Landscape",
      };
      return ratioNames[ratio];
    },

    async resetTemplates() {
      try {
        this.loadingResetTemplates = true;
        await this.refreshAllTemplates();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingResetTemplates = false;
      }
    },

    topicsSelectionResults(topic_sub_ids) {
      this.editedItem.topic_sub_ids = topic_sub_ids;
    },

    customerSelectionResults(customer_ids) {
      this.editedItem.customer_ids = customer_ids;
    },

    async fetchTopics() {
      if (this.topicsBeauty.length === 0)
        this.topicsBeauty = await this.getTopicList("beauty");
      if (this.topicsRealestate.length === 0)
        this.topicsRealestate = await this.getTopicList("real_estate");
    },

    getCustomers(domains) {},

    getTopics(domains) {
      let topicList = [];
      if (domains.includes("beauty")) topicList.push(...this.topicsBeauty);
      if (domains.includes("real_estate"))
        topicList.push(...this.topicsRealestate);
      return topicList;
    },

    openInBannerbear(item) {
      const url = `https://app.bannerbear.com/projects/2by4GqMJgG61ERad9x/templates/${item.external_id}/edit`;
      window.open(url, "_blank");
    },
    tagsChanged(val) {
      if (!val && !val.length) return;
      this.editedItem.tags = H.tagsCompose(val);
    },
    deleteTagHandler(tag) {
      this.editedItem.tags = this.editedItem.tags.filter((val) => val !== tag);
    },

    async saveItemConfirm() {
      try {
        let params = this.editedItem;
        await this.modifyTemplate(params);
      } finally {
        this.editDialog = false;
        await this.fetchTemplates();
      }
    },

    isIntersaction(array1, array2) {
      if (array1 == undefined || array2 == undefined) return true;
      const intersection = array1.filter((element) => array2.includes(element));
      const val = intersection.some((v) => v);
      console.log("isIntersaction", val);
      return val;
    },

    editItem(item) {
      this.editedIndex = this.fetchedTemplates.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.currentEditOptionalTopics = this.getTopics(this.editedItem.domains);
      this.currentEditOptionalCustomers = this.getCustomers(
        this.editedItem.domains
      );
      this.forceAllTopics = Boolean(
        this.editedItem.name.toLowerCase().includes("story")
      );

      const currentEditOptionalTemplateTypesDomains =
        this.optionalTemplateTypes.filter((t) =>
          this.isIntersaction(t.domains, this.editedItem.domains)
        );
      console.log(
        "currentEditOptionalTemplateTypesDomains",
        currentEditOptionalTemplateTypesDomains
      );
      console.log("this.editedItem", this.editedItem);
      this.currentEditOptionalTemplateTypes =
        currentEditOptionalTemplateTypesDomains.filter(
          (t) =>
            t.imagesCount == undefined ||
            t.imagesCount === this.editedItem.images_count
        );

      this.editDialog = true;
    },
    async fetchTemplates() {
      try {
        this.isLoading = true;
        let params = {};
        this.fetchedTemplates = await this.listTemplates(params);
      } catch (e) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  height: 100%;
  padding: 20px;
}
.content-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  div {
    align-self: center;
    h2 {
      font-size: 40px;
      text-align: center;
    }
    img {
      height: 200px;
      width: 200px;
    }
  }
}
</style>
