<template>
  <div>
    <br />
    <strong> Topics: {{ selectedTopics.length }} </strong>
    <span v-if="isAllSelected"> Full </span>
    <span v-else-if="selectedTopics.length > 0"> Partial </span>
    <v-switch
      style="display: inline-flex"
      v-if="!forceAllTopics"
      v-model="edit"
      label="Edit"
    ></v-switch>

    <v-card v-if="edit">
      <v-card-text>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-feature-search-outline"
          label="Search"
          single-line
          hide-details
          autofocus
        />
        <v-data-table
          :headers="headers"
          :items="optionalTopics"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': rowsPerPageItems }"
          :options="options"
          :mobile-breakpoint="null"
          item-key="id"
          class="elevation-1"
          :search="search"
          show-select
          v-model="selectedTopics"
          @toggle-select-all="selectAll"
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    editDialog: {
      type: Boolean,
      default: false,
    },
    forceAllTopics: {
      type: Boolean,
      default: false,
    },
    optionalTopics: {
      type: Array,
      default: () => [],
    },
    previousSelectedTopicSubIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      edit: false,
      selectedTopics: [],
      isAllSelected: false,
      search: "",
      rowsPerPageItems: [5, 10, 20, 100],
      options: {
        sortBy: ["domain", "alias"],
        sortDesc: [true, false],
        multiSort: true,
        itemsPerPage: 5,
      },
      headers: [
        {
          text: "id",
          value: "id",
          align: " d-none",
        },
        {
          text: "alias",
          value: "alias",
        },
        {
          text: "domain",
          value: "domain",
        },
      ],
    };
  },

  computed: {
    selectedTopicsNames() {
      const names = this.selectedTopics.map((p) => p.alias);
      return names.join(", ");
    },
  },

  created() {
    this.init();
  },
  watch: {
    editDialog(val) {
      if (val) {
        this.init();
      }
    },
    async selectedTopics() {
      this.$emit(
        "select",
        this.isAllSelected ? ["all"] : this.selectedTopics.map((p) => p.subId)
      );
    },
  },
  methods: {
    selectAll(event) {
      if (event.value) {
        this.selectedTopics = this.optionalTopics;
        this.isAllSelected = true;
      } else {
        this.selectedTopics = [];
        this.isAllSelected = false;
      }
    },

    initData() {
      this.loading = false;
      this.edit = false;
      this.selectedTopics = [];
      this.isAllSelected = false;
      this.search = "";
    },

    initPreviousSelectedTopicSubIds() {
      if (this.forceAllTopics) {
        this.selectedTopics = [...this.optionalTopics];
        this.isAllSelected = true;
        return;
      }

      if (
        !(
          this.previousSelectedTopicSubIds &&
          this.previousSelectedTopicSubIds.length > 0
        )
      ) {
        this.isAllSelected = false;
        this.selectedTopics = [];
        return;
      }

      if (this.previousSelectedTopicSubIds[0] === "all") {
        this.selectedTopics = [...this.optionalTopics];
        this.isAllSelected = true;
        return;
      }
      this.isAllSelected = false;
      this.selectedTopics = this.optionalTopics.filter((p) =>
        this.previousSelectedTopicSubIds.includes(p.subId)
      );
    },
    init() {
      this.initData();
      this.initPreviousSelectedTopicSubIds();
    },
  },
};
</script>
